import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import IntlService from '../../services/intl/IntlService';

interface IIntlServiceProvider {
  children: JSX.Element;
}

const IntlServiceProvider: FC<IIntlServiceProvider> = ({ children }) => {
  const intl = useIntl();

  useEffect((): void => {
    IntlService.init(intl);
  }, [intl]);

  return children;
};

export default IntlServiceProvider;
