// libraries
import React, { useMemo, useState } from 'react';
// interfaces
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IBilling, ISelectableBilling } from 'common/components/PersonProfile/interfaces';
import { ISelectedData } from 'common/hooks/useMultipleSelect';
// components
import { EditableLabel, NumberTextField, Table, TooltipTypography } from 'common/components';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { getBillingItemDescription, getCurrentPageList } from '../utils';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import { FormattedMessage } from 'react-intl';
import tableHeaders from 'common/messages/tableHeaders';
import messages from 'common/components/PersonProfile/messages';
import { formatPrice } from 'common/utils';
import { CustomTheme } from 'common/ui/interfaces';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import BillingDateChangeDialog from '../../BillingScheduleModal/BillingDateChangeDialog';
import useMultipleSelectTableControl, {
  IMultipleSelectTableControlProps,
} from 'common/hooks/useMultipleSelectTableControl';
import { TableOrderByParams } from 'common/constants';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import tableFilters from 'common/messages/tableFilters';
import { removeTaxAmount } from 'modules/services/utils/billingUtils';

interface IBillingPriceCell {
  id: string;
  price: number;
  onChange: (id: string, value: number) => void;
}

export const BillingPriceCell: React.FC<IBillingPriceCell> = ({
  id,
  price,
  onChange,
}: IBillingPriceCell): JSX.Element => {
  const [internalValue, setInternalValue] = useState<number>(price);

  return (
    <EditableLabel
      labelComponent={
        <TooltipTypography variant="h6" ellipsized>{`$${price.toFixed(2)}`}</TooltipTypography>
      }
      inputComponent={
        <NumberTextField
          style={{ maxWidth: '75px' }}
          autoFocus
          numberFormatProps={{
            prefix: '$',
            decimalScale: 2,
            fixedDecimalScale: true,
            allowLeadingZeros: false,
            allowNegative: false,
            maxLength: 10,
          }}
          value={internalValue}
          onChange={value => setInternalValue(+value)}
        />
      }
      onBlur={() => onChange(id, internalValue)}
    />
  );
};

const tableFilterSettings: IFilterSettings[] = [
  {
    name: 'range',
    title: <FormattedMessage {...tableFilters.period} />,
    type: FilterTypes.DATE_RANGE,
    options: {
      startDate: null,
      endDate: null,
    },
  },
];

const headerOptions: IHeadCell[] = [
  { id: TableOrderByParams.DATE, label: <FormattedMessage {...tableHeaders.date} />, sort: true },
  { id: 'price', label: <FormattedMessage {...tableHeaders.price} />, sort: false },
  { id: 'packageBillingInfo', label: <FormattedMessage {...tableHeaders.name} />, sort: false },
];

const useStyles = makeStyles((theme: CustomTheme) => ({
  tableWrapper: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
  },
  totalLabel: {
    fontSize: '1.25rem',
    textAlign: 'right',
    marginTop: theme.spacing(1.5),
  },
  editButton: {
    marginLeft: theme.spacing(1),

    '& svg': {
      width: 16,
      height: 16,
    },
  },
}));

interface IBillingTableProps {
  billings: ISelectableBilling[];
  isBillingsLoading?: boolean;
  onBillingChanged?: (billingItemId: string, amount: number) => void;
  onSelect: (selectData: ISelectedData) => void;
  onChangeParams: (tableProps: ITableParams) => void;
  tableParams: ITableParams;
}

export const BillingsTable: React.FC<IBillingTableProps> = ({
  billings,
  isBillingsLoading,
  onBillingChanged,
  onSelect,
  onChangeParams,
  tableParams,
}: IBillingTableProps): JSX.Element => {
  const [editableBillingItem, setEditableBillingItem] = useState<IBilling>(null);
  const [billingsSelectParams, setBillingsSelectParams] = useState<
    Partial<IMultipleSelectTableControlProps>
  >({
    isAllSelected: false,
    selected: [],
    excluded: [],
  });

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  // initial state

  const initialSelected: string[] = [];

  billings.forEach(billing => {
    if (billing.selected) {
      initialSelected.push(billing.id);
    }
  });

  const currentPageBillings = useMemo(
    () => getCurrentPageList(billings, tableParams.page, tableParams.perPage),
    [billings, tableParams.page, tableParams.perPage],
  );

  // handlers

  const handleBillingChange = (id: string, value: number) => {
    onBillingChanged(id, value);
  };

  const onChangeTableParamsSubscriber = (rowsData: IMultipleSelectTableControlProps) => {
    setBillingsSelectParams(rowsData);
  };

  const { ref } = useMultipleSelectTableControl({
    onChangeTableParamsSubscriber,
  });

  // table configuration

  const rows: ITableRow[] = currentPageBillings.map(billing => ({
    id: billing.id,
    cells: [
      {
        align: 'left',
        label: '',
        maxWidth: '100px',
        cellComponent: (
          <>
            {billing.paymentDate ? (
              <Box display="flex" alignItems="center">
                <Typography variant="h5">
                  {timezoneMoment(billing.paymentDate, 'YYYY-MM-DD').format(DEFAULT_DATE_FORMAT)}
                </Typography>

                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => setEditableBillingItem(billing)}
                  className={classes.editButton}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            ) : (
              '-'
            )}
          </>
        ),
      },
      {
        align: 'left',
        label: '',
        cellComponent: (
          <BillingPriceCell
            id={billing.id}
            price={
              billing.revenueCode?.totalTax
                ? removeTaxAmount(billing.amount, billing.revenueCode.totalTax)
                : billing.amount
            }
            onChange={handleBillingChange}
          />
        ),
      },
      {
        align: 'left',
        label: '',
        cellComponent: <Typography>{getBillingItemDescription(billing.packageInfo)}</Typography>,
      },
    ],
  }));

  const billingsTotal = useMemo(() => {
    return billings.reduce((totalAmount, billingItem) => {
      if (billingItem.selected && !billingsSelectParams.excluded.includes(billingItem.id)) {
        const { amount, revenueCode } = billingItem;

        return (
          totalAmount +
          (revenueCode?.totalTax ? removeTaxAmount(amount, revenueCode.totalTax) : amount)
        );
      }

      return totalAmount;
    }, 0);
  }, [billings, billingsSelectParams.excluded]);

  // renders

  return (
    <>
      <Box mt={2} className={classes.tableWrapper}>
        <Table
          activeSelect
          showPerPageSelect
          rows={rows}
          isLoading={Boolean(isBillingsLoading)}
          initialSelected={initialSelected}
          onSelect={onSelect}
          filters={tableFilterSettings}
          headerOptions={headerOptions}
          onChangeParams={onChangeParams}
          totalRows={billings?.length}
          refMultipleSelectParams={ref}
          tableParams={tableParams}
        />
      </Box>

      <Typography component="p" className={classes.totalLabel}>
        <FormattedMessage
          {...messages.pastDuesModalTotalLabel}
          values={{
            price: (
              <Typography component="span" variant="h3">
                {formatPrice(billingsTotal)}
              </Typography>
            ),
          }}
        />
      </Typography>

      {!!editableBillingItem && (
        <BillingDateChangeDialog
          isOpen={!!editableBillingItem}
          billing={editableBillingItem}
          onSubmit={() => setEditableBillingItem(null)}
          onCancel={() => setEditableBillingItem(null)}
        />
      )}
    </>
  );
};
