import axios from 'axios';
import ApiService from 'services/network/ApiService';

import {
  IBankProcessorConfiguration,
  IBankRemittanceInfo,
  IBankViewInfoDto,
  IClubFeeLate,
  IClubFeeMonthly,
  IClubFeeRatesData,
  IClubFeeReturn,
  IClubFeeSingle,
  IClubFeeTransaction,
  IClubProcessingFeeRates,
  IPaymentBankInfo,
} from 'modules/clubs/interfaces';
import { IMultipleSelectData, ITableParams } from 'common/interfaces/table';
import { ActionResult } from 'common/constants';
import {
  ICorporationBankInfo,
  ICorporationBillingInfo,
  ICorporationGeneralInfo,
  ICorporationGeneralInfoView,
  ICorporationOtherInfo,
  ICorporationPayPeriodItem,
  ICorporationProcessorConfigInfo,
  ICorporationTaxpayerInfo,
  IPaginatedPayPeriods,
  IPeriodItem,
} from 'modules/corporations/interfaces';
import { IRequiredFieldInfo, IRequiredFieldUpdateDto } from 'modules/corporate-settings/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IShortPerson } from 'common/interfaces/common';

export default class CorporationSettings extends ApiService {
  public getCorporationGeneralInfo = (corporationId: string): Promise<ICorporationGeneralInfo> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}`);

  public getCorporationGeneralInfoView = (
    corporationId: string,
  ): Promise<ICorporationGeneralInfoView> => {
    return axios.get(`/api/v1/corporate-settings/${corporationId}/view`);
  };

  public getCorporationBankInfo = (corporationId: string): Promise<ICorporationBankInfo> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/bank-info`);

  public getCorporationBankInfoView = (corporationId: string): Promise<IBankViewInfoDto> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/bank-info/view`);

  public getCorporationBillingInfo = (corporationId: string): Promise<ICorporationBillingInfo> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/billing-info`);

  public getCorporationProcessorConfigInfo = (
    corporationId: string,
  ): Promise<ICorporationProcessorConfigInfo> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/processor-config`);

  public getCorporationTaxpayerInfo = (corporationId: string): Promise<ICorporationTaxpayerInfo> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/taxpayer-info`);

  public getCorporationOtherInfo = (corporationId: string): Promise<ICorporationOtherInfo> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/other-info`);

  public getPayPeriodById = (payPeriodId: string): Promise<IPeriodItem> =>
    axios.get(`/api/v1/pay-periods/${payPeriodId}`);

  public deactivatePayPeriods = (
    corporationId: string,
    settingId: string,
    requestData: IMultipleSelectData,
  ): Promise<ActionResult> =>
    axios.put(
      `/api/v1/corporations/${corporationId}/pay-period-settings/${settingId}/pay-periods/deactivate`,
      this.transformMultiselectDataToBulkDTO(requestData),
    );

  public updatePayPeriod = (payPeriodId: string, requestData: IPeriodItem): Promise<IPeriodItem> =>
    axios.put(`/api/v1/pay-periods/${payPeriodId}`, requestData);

  public changePayPeriodStatus = (
    payPeriodId: string,
    requestData: { active: boolean },
  ): Promise<IPeriodItem> =>
    axios.patch(`/api/v1/pay-periods/${payPeriodId}/change-status`, requestData);

  public getCorporationPayPeriods = (
    corporationId: string,
    settingId: string,
    requestOptions?: ITableParams,
  ): Promise<IPaginatedPayPeriods> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(
      `/api/v1/corporations/${corporationId}/pay-period-settings/${settingId}/pay-periods`,
      { params: searchParams },
    );
  };

  public updateCorporationGeneralInfo = (
    corporationId: string,
    requestData: ICorporationGeneralInfo,
  ): Promise<ICorporationGeneralInfo> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}`, requestData);

  public updateCorporationBankInfo = (
    corporationId: string,
    requestData: ICorporationBankInfo,
  ): Promise<ICorporationBankInfo> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/bank-info`, requestData);

  public updateCorporationBillingInfo = (
    corporationId: string,
    requestData: ICorporationBillingInfo,
  ): Promise<ICorporationBillingInfo> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/billing-info`, requestData);

  public updateCorporationProcessorConfigInfo = (
    corporationId: string,
    requestData: ICorporationProcessorConfigInfo,
  ): Promise<ICorporationProcessorConfigInfo> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/processor-config`, requestData);

  public updateCorporationTaxpayerInfo = (
    corporationId: string,
    requestData: ICorporationTaxpayerInfo,
  ): Promise<ICorporationTaxpayerInfo> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/taxpayer-info`, requestData);

  public updateCorporationOtherInfo = (
    corporationId: string,
    requestData: ICorporationOtherInfo,
  ): Promise<ICorporationOtherInfo> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/other-info`, requestData);

  public updateCorporationBankRemittanceInfo = (
    corporationId: string,
    requestData: IBankRemittanceInfo,
  ): Promise<IBankRemittanceInfo> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/bank-info/remittance-info`, requestData);

  public updateCorporationCreditCardBankInfo = (
    corporationId: string,
    requestData: IPaymentBankInfo,
  ): Promise<IPaymentBankInfo> =>
    axios.put(
      `/api/v1/corporate-settings/${corporationId}/bank-info/credit-card-bank-info`,
      requestData,
    );

  public updateCorporationEcheckBankInfo = (
    corporationId: string,
    requestData: IPaymentBankInfo,
  ): Promise<IPaymentBankInfo> =>
    axios.put(
      `/api/v1/corporate-settings/${corporationId}/bank-info/echeck-bank-info`,
      requestData,
    );

  public updateCorporationPayPeriodInfo = (
    corporationId: string,
    requestData: ICorporationPayPeriodItem,
  ): Promise<ICorporationPayPeriodItem> =>
    axios.put(
      `/api/v1/corporations/${corporationId}/pay-period-settings/${requestData.id}`,
      requestData,
    );

  public getPayPeriodSettingInfoById = (
    corporationId: string,
    settingId: string,
  ): Promise<ICorporationPayPeriodItem> =>
    axios.get(`/api/v1/corporations/${corporationId}/pay-period-settings/${settingId}`);

  public createCorporationPayPeriodInfo = (
    corporationId: string,
    requestData: ICorporationPayPeriodItem,
  ): Promise<ICorporationPayPeriodItem> =>
    axios.post(`/api/v1/corporations/${corporationId}/pay-period-settings`, requestData);

  public getAllCorporationPayPeriodsSettings = (
    corporationId: string,
  ): Promise<ICorporationPayPeriodItem[]> =>
    axios.get(`/api/v1/corporations/${corporationId}/pay-period-settings`);

  public deletePayPeriodSetting = (corporationId: string, settingId: string): Promise<void> =>
    axios.delete(`/api/v1/corporations/${corporationId}/pay-period-settings/${settingId}`);

  public updateCorporationBankProcessorConfigInfo = (
    corporationId: string,
    requestData: IBankProcessorConfiguration,
  ): Promise<IBankProcessorConfiguration> =>
    axios.put(
      `/api/v1/corporate-settings/${corporationId}/bank-info/processor-config`,
      requestData,
    );

  public changeCorporationStatus = (
    corporationId: string,
    isActive: boolean,
  ): Promise<ICorporationGeneralInfo> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/change-status`, { active: isActive });

  public getRequiredFields = (corporationId: string): Promise<IRequiredFieldInfo[]> => {
    return axios.get(`/api/v1/corporate-settings/${corporationId}/profile-required-info`);
  };

  public changeRequiredFieldNecessity = (
    corporationId: string,
    data: IRequiredFieldUpdateDto,
  ): Promise<IRequiredFieldInfo> => {
    const { fieldId, ...payload } = data;
    return axios.put(
      `/api/v1/corporate-settings/${corporationId}/profile-required-info/${fieldId}`,
      payload,
    );
  };

  public getEmployees = (
    corporationId: string,
    { searchStr, ...params }: Omit<ITableParams, 'order' | 'orderBy' | 'filters' | 'calendarView'>,
  ): Promise<IPaginatedData<IShortPerson & { email: string }>> => {
    return axios.get(`/api/v1/corporate-settings/${corporationId}/dictionary/employees`, {
      params: {
        ...params,
        search: searchStr,
      },
    });
  };

  public getCorporationProcessingFeeRates = (
    corporationId: string,
  ): Promise<IClubProcessingFeeRates> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-processing-info`);

  public updateCorporationProcessingFeeRates = (
    corporationId: string,
    requestData: IClubProcessingFeeRates,
  ): Promise<IClubProcessingFeeRates> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/fees/fee-processing-info`, requestData);

  public getPeakProcessingFeeRates = (corporationId: string): Promise<IClubProcessingFeeRates> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-processing-info/peak`);

  public getCorporationFeeSingleRates = (corporationId: string): Promise<IClubFeeSingle> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-single-info`);

  public updateCorporationFeeSingleRates = (
    corporationId: string,
    requestData: IClubFeeSingle,
  ): Promise<IClubFeeSingle> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/fees/fee-single-info`, requestData);

  public getPeakFeeSingleRates = (corporationId: string): Promise<IClubFeeSingle> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-single-info/peak`);

  public getCorporationFeeLateRates = (corporationId: string): Promise<IClubFeeLate> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-late-info`);

  public updateCorporationFeeLateRates = (
    corporationId: string,
    requestData: IClubFeeLate,
  ): Promise<IClubFeeLate> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/fees/fee-late-info`, requestData);

  public getPeakFeeLateRates = (corporationId: string): Promise<IClubFeeLate> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-late-info/peak`);

  public getCorporationFeeReturnRates = (corporationId: string): Promise<IClubFeeReturn> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-return-info`);

  public updateCorporationFeeReturnRates = (
    corporationId: string,
    requestData: IClubFeeReturn,
  ): Promise<IClubFeeReturn> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/fees/fee-return-info`, requestData);

  public getPeakFeeReturnRates = (corporationId: string): Promise<IClubFeeReturn> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-return-info/peak`);

  public getCorporationFeeMonthlyRates = (corporationId: string): Promise<IClubFeeMonthly> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-monthly-info`);

  public updateCorporationFeeMonthlyRates = (
    corporationId: string,
    requestData: IClubFeeMonthly,
  ): Promise<IClubFeeMonthly> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/fees/fee-monthly-info`, requestData);

  public getPeakFeeMonthlyRates = (corporationId: string): Promise<IClubFeeMonthly> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-monthly-info/peak`);

  public getCorporationFeeTransactionRates = (
    corporationId: string,
  ): Promise<IClubFeeTransaction> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-transaction-info`);

  public updateCorporationFeeTransactionRates = (
    corporationId: string,
    requestData: IClubFeeTransaction,
  ): Promise<IClubFeeTransaction> =>
    axios.put(`/api/v1/corporate-settings/${corporationId}/fees/fee-transaction-info`, requestData);

  public getPeakFeeTransactionRates = (corporationId: string): Promise<IClubFeeTransaction> =>
    axios.get(`/api/v1/corporate-settings/${corporationId}/fees/fee-transaction-info/peak`);
}
