import React, { useEffect, useState } from 'react';
import { Alert, DialogComponent } from '../index';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'common/state/invoice/actions';
import { selectCommerceHubCredentials } from '../../state/invoice/selectors';
import { AlertTypes } from '../../interfaces/alerts';
import { FormattedMessage } from 'react-intl';
import messages from '../InvoiceOperating/messages';

interface CommerceHubIFrameModalProps {
  memberId: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (event) => void;
}

const CommerceHubIFrameModal = ({
  memberId,
  isOpen,
  onClose,
  onSubmit,
}: CommerceHubIFrameModalProps) => {
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState(null);
  const commerceHubCredentials = useSelector(selectCommerceHubCredentials);

  const handleSuccessSubmit = event => {
    onSubmit(event);
  };

  const handleSubmit = () => {
    setFormErrors(null);
    (document.getElementById('commerceHubIFrameId') as HTMLIFrameElement).contentWindow.postMessage(
      'SUBMIT_FORM',
    );
  };

  useEffect(() => {
    dispatch(actions.fetchCommerceHubCredentials(memberId));

    function proceedEvent(event) {
      const data = event?.data;
      if (data) {
        if (data.result === 'SUCCESS') {
          handleSuccessSubmit(event.data.sessionId);
        } else if (data.result === 'VALIDATION_ERROR') {
          if (data.message === 'BAD_FORM_DATA') {
            setFormErrors(
              <FormattedMessage
                {...messages.creditCardCommerceHubTokenizeBadFormData}
                values={{ variable_1: data.errors?.join(',') }}
              />,
            );
          }
          if (data.message === 'BAD_SUBMIT_CONFIG') {
            setFormErrors(
              <FormattedMessage
                {...messages.creditCardCommerceHubTokenizeBadSubmitConfig}
                values={{ variable_1: data.errors?.join(',') }}
              />,
            );
          }
        } else if (data.result === 'HTTP_ERROR') {
          const gateway =
            !!data.gatewayCode && !!data.gatewayMessage
              ? ` Gateway error code: ${data.gatewayCode} - ${data.gatewayMessage}`
              : '';
          setFormErrors(
            <FormattedMessage
              {...messages.creditCardCommerceHubTokenizeHttpError}
              values={{
                variable_1: data.statusCode,
                variable_2: data.statusText,
                variable_3: gateway,
              }}
            />,
          );
        } else if (data.result === 'UNDEFINED_ERROR') {
          setFormErrors(
            <FormattedMessage {...messages.creditCardCommerceHubTokenizeUndefinedError} />,
          );
          console.error(JSON.stringify(data));
        } else {
          // console.log(`Unexpected action: ${data}`);
        }
      }
    }

    // Attach the scroll event listener to the window object
    window.addEventListener('message', proceedEvent);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('message', proceedEvent);
    };
    // eslint-disable-next-line
  }, []);

  const passParamsToIFrame = () => {
    return (
      '/commerceHubIFrame.html?' +
      `apiKey=${commerceHubCredentials.apiKey}` +
      `&publicKey=${commerceHubCredentials.publicKey}` +
      `&accessToken=${commerceHubCredentials.accessToken}` +
      `&keyId=${commerceHubCredentials.keyId}` +
      `&merchantId=${commerceHubCredentials.merchantId}` +
      `&terminalId=${commerceHubCredentials.terminalId}` +
      `&sessionId=${commerceHubCredentials.sessionId}`
    );
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      title={<FormattedMessage {...messages.creditCardCommerceHubTokenizeTitle} />}
      onClose={onClose}
      onSubmit={handleSubmit}
      size="md"
    >
      {commerceHubCredentials && (
        <iframe
          title="commerceHubIFrame"
          id="commerceHubIFrameId"
          src={passParamsToIFrame()}
          style={{ height: '180px', width: '600px', borderStyle: 'none' }}
        />
      )}
      {formErrors && <Alert title={formErrors} severity={AlertTypes.Danger} />}
    </DialogComponent>
  );
};

export default CommerceHubIFrameModal;
