import { Browser } from '../types/browser';

export function getBrowser(): Browser | null {
  // eslint-disable-next-line prefer-destructuring
  const userAgent = navigator.userAgent;

  console.log('call');

  if ((userAgent.indexOf('Opera') !== -1 || userAgent.indexOf(' OPR/')) !== -1) {
    return 'Opera';
  } else if (userAgent.indexOf('Edg') !== -1) {
    return 'Edge';
  } else if (userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  } else if (userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  } else if (userAgent.indexOf('Firefox') !== -1) {
    return 'FireFox';
  } else if (
    userAgent.indexOf('MSIE') !== -1 ||
    ('documentMode' in document && !!document.documentMode === true)
  ) {
    return 'IE';
  } else {
    return null;
  }
}
